@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
}
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
  animation-delay: 0.2s;
}

.fadeInstant {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

body {
  @apply bg-background-100 text-primary-900
}

.paper {
  @apply border border-background-200 shadow-sm rounded-md p-4 bg-background-50
}

.paper-inverse {
  @apply bg-background-200 p-2 shadow-inner rounded-md
}

.input {
  @apply border-background-200 focus:ring-0 focus:border-background-200 font-normal bg-background-50 p-2 rounded-md focus:outline-2 focus:outline-primary-700 border outline-none transition-all ease-linear disabled:border-transparent disabled:bg-background-200 shadow-inner 
}

.input-label {
  @apply text-sm font-medium min-w-[10rem] max-w-[10rem]
}

.input-group {
  @apply flex flex-col gap-1 w-full
}

.btn {
  transition-property: filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  @apply font-medium cursor-pointer p-2 px-4 bg-gradient-to-tr from-primary-700 to-primary-500 rounded-full text-white shadow-sm ease-linear enabled:hover:brightness-110
}

.btn-sm {
  transition-property: filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  @apply font-medium text-sm cursor-pointer p-2 px-3 bg-gradient-to-tr from-primary-700 to-primary-500 rounded-full text-white shadow-sm ease-linear enabled:hover:brightness-110
}

div.btn {
  @apply hover:brightness-110
}

.btn-secondary {
  @apply from-secondary-700 to-secondary-500
}

.btn-danger {
  @apply from-red-600 to-red-400
}

.btn:disabled {
  @apply from-background-700 to-background-500 text-background-300
}

.footer {
  @apply font-light text-sm
}

.link {
  @apply hover:text-primary-700 transition-all ease-linear
}

.scrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100%;
  background: theme("colors.background.200");
}

.scrollbar::-webkit-scrollbar-thumb {
  background: theme("colors.background.50");
  border-radius: 100vh;
  border: 1px solid theme("colors.background.200");
  transition: border 1000ms ease;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  border: 1px solid theme("colors.primary.400");
  transition: all;
}

.error {
  @apply border border-red-500
}

.radio {
  @apply border-background-200 focus:ring-0 focus:border-background-200 font-normal bg-background-50 p-2 rounded-full focus:outline-2 focus:outline-primary-700 border outline-none transition-all ease-linear disabled:border-transparent disabled:bg-background-200 shadow-inner checked:bg-primary-600 text-primary-600
}

.checkbox {
  @apply border-background-200 focus:ring-0 focus:border-background-200 font-normal bg-background-50 p-2 rounded-md focus:outline-2 enabled:focus:outline-primary-700 border outline-none transition-all ease-linear disabled:border-transparent disabled:bg-background-200 shadow-inner enabled:checked:bg-primary-600 text-primary-600 disabled:text-background-200
}